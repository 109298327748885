* {
    margin: 0;
    padding: 0;
   
}

.dspc {
    display: flex;
    justify-content: space-evenly;
    height: 713px;
    margin-top: 30px;
    background-image: url("../img/water-texture-background-rainy-window-night.jpg");
    background-size: cover;
}

.DSPcontainer {
    display: inline-block;
    width: 400px;

}

.DSP-view_list {
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: start;
    border-radius: 50px 20px 20px 20px;
    margin-top: 30px;
    background-color: transparent;
    display: flex;
    backdrop-filter: blur(5px);
    flex-direction: column;
    box-shadow: 10px 30px 20px rgba(0, 0, 0, 0.3);
 border-top: 3px solid #777777;
    border-left: 3px solid #969696;
}

.Form-container {
    width: 50%;
    display: inline-block;
    height: fit-content;
    padding: 20px;
}

.parent-back {

    background: rgba(117, 116, 116, 0.11);
    backdrop-filter: blur(3px);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 3px solid #777777;
    border-left: 3px solid #969696;
    border-bottom: 2px solid #969696;
    background-size: cover;

    width: fit-content;
    height: fit-content;
    margin-top: 30px;
    /* position: absolute; */
    border-radius: 20px;

}



.service-details-list {
    font-size: 1.4em;
    padding: 20px 30px;
    display: inline-block;
    font-weight: bold;
    color: white;
    list-style: square;


}
.service-details-listp{
    color: rgb(138, 255, 138);
    font-size: .8em;
    padding: 5px;
    padding-left: 15px;
    /* margin: 5px; */
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    /* text-shadow: 1px 1px greenyellow; */
    
    background: rgba(163, 163, 163, 0.11);
}

.service-details-list li ,.service-details-list-2 li{
    /* font-size:1.2em; */
    transition: .2s ease-in;
    cursor: pointer;
}

.service-details-list li:hover {
    font-size: 1.3em;

}

.service-details-list-2 li:hover {
    font-size: 1.3em;

}

.label-fullname {
    font-size: 1.2em;
    color: white;
    margin-left: 5%;

}

.input-Name {
    /* background-color: rgba(252, 233, 233, 0.845); */
    text-align: start;
    padding-left: 15px;
    border: 2px solid #08635b;
    font-weight: 700;
    border-radius: 2px;
    height: 35px;
    /* color: #064d47; */
    margin-top: 2px;
    margin-bottom: 5px;
    border-radius: 5px;
    width: 90%;
    margin-left: 5%;


}
.input-timing{
    width: fit-content;
    text-align: start;
    padding-left: 15px;
    border: 2px solid #08635b;
    font-weight: 700;
    border-radius: 2px;
    height: 35px;
    /* color: #064d47; */
    margin-top: 2px;
    margin-bottom: 5px;
    border-radius: 5px;
    /* width: 90%; */
    margin-left: 5%;
}
.rules-list{
    width: 100%;
}
@media screen and (max-width:720px) {
    .dspc{
        display: block;
        height: fit-content;
    }
    .Form-container{
width: 100%;
    }
    .DSPcontainer{
        width: 100%;
        background-color: transparent;
        
        backdrop-filter: blur(0);
    }
    .parent-back{
        background-color: transparent;
        backdrop-filter: blur(0);
        border-right: 1px solid rgb(156, 156, 156);

    }
    .DSP-view_list{
        border-right: 1px solid gray;
        border-bottom: 1px solid gray;


    }
}
/* Outlet plan style here */
/* OutletPan-main-container */
.OutletPan-main-container{
    width:100%;
    height: 800px;
    background-color: aliceblue;
    background-size: cover;
    transition: 0.3s all ease-in-out;
    
    
    background-image: url('../img/water-texture-background-rainy-window-night.jpg');
}
.Main-container{
    display: flex;
    justify-content: center;
    margin-top: 60px;
    
}
.Plan-details-view{
    background: rgba(117, 116, 116, 0.11);
    backdrop-filter: blur(3px);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 3px solid #777777;
    border-left: 3px solid #969696;
    border-bottom: 2px solid #969696;
    width: fit-content;
    height: fit-content;
    margin-top: 30px;

    border-radius: 20px;
}
.service-details-list-2{
    font-size: 1.4em;
    padding: 20px 30px;
    display: inline-block;
    font-weight: bold;
    /* color:#042825; */
    color: white;
   

    list-style: square;
}
.routes-links-design{
    background-color: #08635b;

}
.plans-routes-styles{
   display: inline-block;
   width: calc(100% / 3);
   text-align: center;
   font-size: 1em;
   padding:5px;
   /* height:40px; */
   color: white;
}
.plans-routes-styles h1{
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    /* margin-top: 5px; */
    font-size: 1.2em;
    color: rgb(0, 180, 0);
    font-weight: bold;
    padding: 5px;
    text-align: center;
    /* padding-left: 20px; */
    /* margin-top: 5px; */
    /* margin-bottom: 5px; */
 border-radius: 20px;
    background-color: aliceblue;
    transition: 0.3s all ease-in-out;


}


@media screen and (max-width:720px) {
    .Plan-details-view{
        width:90%;
        background-color: transparent;
        backdrop-filter: blur(0px);
        height: auto;
        margin-top: 30px;
        border-radius: 20px;
        border-right: 1px solid gray;
        
    } 
    .OutletPan-main-container{
        width:100%;
        height:1000px;
        
    }
    .plans-routes-styles h1{
    font-size: 1em;
    font-weight: bold;
        /* padding: 5px; */
        margin: 5px 5px 5px 5px;
        /* margin-right: 5px; */
        /* width: calc((100%; */
        /* font-size: 1.2em; */
        color: #042825;
        /* padding-left: 5px; */
    

    }
   .plans-routes-styles{
    text-align: center;
    padding: 0px;
    

   }
}
.final-book{
  
    width: 150px;
    background-color: white;
    color: green;
    padding: 5px;
    margin:5px;
    font-weight: bold;
    border-radius: 5px;
}
.service-details-list-2 h2{
    color: wheat;
    
    padding: 5px;
    padding-left: 15px;
    /* margin: 5px; */
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    /* text-shadow: 1px 1px greenyellow; */
    /* backdrop-filter: blur(5px); */
    
    background: rgba(163, 163, 163, 0.11);
}
