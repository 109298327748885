
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

.App-header {
  width: 100%;
  min-width: 75px;
  display: block;
  position: fixed;
  transition: top 0.3s;
  background: #042825;
  overflow: hidden;

}

.logo {
  width: 150px;
  height: 100%;
  padding-left: 15px;
}

.menu-collection {
  float: right;
  height: 100%;
}

.menu-items {
  width: max-content;
  display: inline-block;
  padding: 10px;
  margin-top: 15px;
  font-weight: bold;

}

Link {
  text-decoration: none;
}

.SignUp-btn {
  width: 100px;
  height: 35px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 25px;
  font-weight: bold;
  color: #042825;
  font-size: 1.1em;
  background-color: white;
  border-color: #042825;
}

.My_app {
  width: 100%;
  margin: 0;
}

.design {
  color: white;
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
}

.design2 {
  color: #979797;
  display: inline-block;
  position: relative;
  padding-bottom: 5 px;
  text-decoration: none;
}

.design3 {
  color: #042825;
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
  margin-left: 10px;
}

.design:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.design2:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.design3:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #042825;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.design:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.design2:hover:after,
.design3:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.My_app {
  width: 100%;
}

.Slogans {
  width: 100%;

}
.Head-Geo{
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
}

.h1-styleing {
  width: 70%;
  display: inline-block;
  /* padding-top: 60px; */
  padding-left: 20px;
  font-size: 74px;
  color: white;
}

.sec1-para {
  width: 50%;
  padding-left: 20px;
  padding-top: 70px;
  font-size: 2em;
  font-weight: 500;
  color: white;
  line-height: 1.2;
}

.Articles1 {
  width: 100%;
  height: auto;
  margin-top: 60px;
}

.articles_p {
  width: 70%;
  text-align: justify;
  margin-left: 15%;
  margin-right: 15%;
  font-size: 2.5em;
  font-weight: 600;
  color: white;

}

.Workh {
  width: 45%;

  padding-top: 90px;

  font-size: 3em;
  color: #042825;
  padding-left: 2%;
  line-height: 1.6;
}

.newsec1 {
  font-size: 2em;
  text-indent: 70px;
  color: #042825;
  width: 60%;
  text-align: justify;
  padding-left: 2%;
  padding-top: 30px;
  padding-bottom: 40px;

}

.services-display {
  width: 100%;
  height: auto;
  padding: 15px;
  /* margin-top: 50px; */
}

.img1 {
  width: 592px;
  height: 592px;
  transition: 0.3s all ease-in-out;
}


.img3 {
  width: 1000px;
  height: 717px;
  transition: 0.3s all ease-in-out;
}

.ImageCol {
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: center;
}

.images-hold1 {

  width: 592px;
  height: 592px;
  overflow: hidden;
  margin: 0 auto;
}

.images-hold3 {

  width: 1000px;
  height: 717px;
  overflow: hidden;
  margin: 0 auto;
}

.images-hold1:hover .img1 {
  /* transform: scale(1.2); */

  transform: scale(1.1) translateZ(0);
}

.images-hold3:hover .img3 {
  /* transform: scale(1.2); */
  transform: scale(1.1) translateZ(0);
}

.Services-content {
  display: inline-block;
  margin: 10px;

}

.attached_headings {
  font-size: 1.5em;
  width: 592px;

  /* margin: 10px; */
  margin-top: 20px;
  /* text-decoration: underline; */

}

.attached_headings1 {
  font-size: 1.5em;
  /* width: 592px; */

  /* margin: 10px; */
  margin-top: 20px;
  /* text-decoration: underline; */

}

.attached_content {

  color: #042825;
  display: inline-block;
  width: 100%;
}

.Form_wrap {
  width: 350px;
  display: inline-block;
  animation: fadeoutAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  /* padding-top: 60px; */
  
  /* float: right; */
}
@keyframes fadeoutAnimation {
  0%{

      transform: translateY(-60px);
      opacity: 0;
  }
  100%{
      transform: translateY(0);
      opacity: 1;
  }
}

.form-container {
  /* padding-top: 90px; */
  width: 300px;
  height: auto;
  float: right;
  margin-right: 50px;
  padding: 25px;
  z-index: 1;
  /* display: inline; */
  background: rgba(6, 70, 65, 0.11);
  border-radius: 16px;
  overflow: hidden;
  margin-top: 20px;
  border-top: 2px solid #01524b;
  border-left: 2px solid #01524b;
  /* backdrop-filter: blur(5px); */
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
}
.PhoneNo_input{
  margin-bottom: 20px;

  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);

}

.fheadings {
  width: fit-content;
  height: 90px;
  text-align: left;
}

.locImg {
  width: 46px;
  height: 45px;
}

.locBtn {
  margin: 0;
  padding: 0;
  margin-left: 1px;
  cursor: pointer;
  width: 50px;
  /* display: inline-block; */
  margin-top: 0px;
  height: 50px;
  
  /* position: absolute; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #042825;
  font-size: 16px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-color: transparent;
  /* background-image: url("./img/LI.png"); */

}

.edLocation {
  /* display: inline-block; */
  height: 50px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-color: rgb(168, 252, 129);
}

.SuggestList-container {
  width: 200px;
  color: white;
  display: inline-block;
  border-radius: 15px;
  /* border: 1px solid white; */
  list-style: none;
  /* padding: 10px; */
  cursor: pointer;
  width: 200px;

}

.SuggestList {
  box-sizing: border-box;
  /* border-bottom: black; */
  border-radius: 10px;
  border: 1px solid white;
  width: 100%;
  padding: 5px;
  font-size: 1rem;
  /* position:absolute; */
  /* margin-right: 20px; */
}

.SuggestList:hover {
  background-color: white;
  color: #042825;
  border: 1px solid #042825;
  font-weight: bold;

}

.inNumber {
  width: 100%;
  height: 45px;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
  /* float: right; */
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  font-size: 16px;
  border-color: rgb(168, 252, 129);
  border-radius: 10px;


}

.btn-gotoplans {
  width: 140px;
  border-radius: 20px;
  float: right;
  margin-top: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  background-color: #042825;
  padding: 10px;
  /* margin-left: 15px; */
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  border-color: #fff;
}


@media screen and (max-width:450px) {
  .services-display {
    width: 100%;
    height: auto;
    /* margin-top: 50px; */
  }
  .Head-Geo{
    flex-direction: column;
  }
  

  .images-hold1 {

    width: 100%;
    height: 320px;
    overflow: hidden;
    /* padding: 10px; */
    margin: 0 auto;
  }

  .images-hold3 {

    width: 100%;
    height: 350px;
    /* padding: 10px; */
    overflow: hidden;
    margin: 0 auto;
  }

  .img1 {
    width: 100%;
    height: 350px;
    transition: 0.3s all ease-in-out;
  }

  .img2 {
    width: 100%;
    height: 350px;
    transition: 0.3s all ease-in-out;
  }

  .img3 {
    width: 100%;
    height: 350px;
    transition: 0.3s all ease-in-out;
  }

  .Workh {
    width: 100%;
    padding-top: 100px;
    font-size: 2.5em;
    color: #042825;
    padding: 20px;
    /* line-height: 1.6;s */
  }

  .newsec1 {
    width: 100%;
    padding: 20px;
    font-size: 1.5em;
  }

  .articles_p {
    width: 90%;
    text-align: justify;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 2em;
    font-weight: 600;
    color: white;

  }

  .ImageCol {
    padding: 15px;
    display: block;
    justify-items: center;
  }

  .ImageCol .Services-content {
    width: 100%;
  }

  .attached_headings {
    font-size: 1.5em;
    width: 100%;
    margin-top: 20px;
  }

  .form-container {
    padding-top: 0;
    width: 80%;
    height: auto;
    margin-bottom: 40px;
    display: inline-block;

    /* padding-left: 5%; */

    padding: 25px;
    background: rgba(6, 70, 65, 0.11);
    border-radius: 16px;
    border-top: 2px solid #01524b;
    border-left: 2px solid #01524b;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  }

  .h1-styleing {
    display: block;
    width: 100%;
    padding-top: 0px;
    padding-left: 20px;
    font-size: 55px;
    text-align: start;
  }

  .sec1-para {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    font-size: 1.5em;
    line-height: 1.2;
    text-align: justify;
  }

  .articles_p {
    font-size: 1.5em;
  }

  .design3 {
    padding-bottom: 5px;
  }
}


/* footer styles: */
.Footer-content{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: auto;
  padding: 2%;
  background-color:#042825;
  border-radius: 25px;
  display: flex;
}
.foot-img-view{
  width: 30%;
  padding: 20px;
 
  
}
.foot-links-content{
  width: 70%;
  display: inline-flexbox;
  background-color:#042825;
}
.Email-in-join_us-btn{
  width: 70%;
  margin-left: 25%;
  margin-right: 5%;
  height: 90px;
  padding: 10px;
  display: flex;
  margin-top: 30px;
  transition: 0.3s all ease-in-out;

}
.input-mail{
  width: 100%;
  height: 45px;
  font-size: 1.2em;
  border-radius: 30px  0 0 30px ;
  padding-left: 40px; 
  border: 3px solid #ffffff;
}
.joinus-foot-btn{
  width: 30%;
  height: 45px;
  
  font-size: 1.2em;
  border: 2px solid #ffffff;
  font-weight: bold;
  background-color: white;
   border-radius: 0 60px 60px 0 ;
   color: #073e3a;
}
.links-container-footer{
  
  display: flex;
  justify-content: space-evenly;
  color: white;
  text-align: start;

}
.links-content1{
    width: 60%;
    height: auto;
    background-color:#042825;
}
.links-content2{
  width: 40;
  height: auto;
  background-color:#042825;
}
.footer-web-logo{
  width: 180px;
  height: 80px;

  
}
.expert{
  font-size: 1.5em;
  color: gray;
  display: inline-flexbox;
  height: 80px;
  padding-top: 35px;
  font-weight: bold;
  margin-left: -15px;
}
.links-content1 h3{
  text-decoration: underline;
  padding: 5px;
  margin-bottom: 10px;
}
.links-content2 h3{
  text-decoration: underline;
  padding: 5px;
  margin-bottom: 10px;
}
.links-content1 a{
  color: white;
  font-size: 1.2em;
  line-height: 2em;
  text-decoration: none;
}
.links-content2 a{
  color: white;
  font-size: 1.2em;
  line-height: 2em;
  text-decoration: none;
}
.details-web{
  color: white;
  display: block;
}
.foot-logoC{
  display: flex;
}
#numStyle{
    text-decoration: none;
    font-size: 1.2em;
    line-height: 2em;
    color: white;
}
.IconsLink{
  width: 20px;
  margin-right: 5px;
  display: inline-block;
}
.IconsLink1{
  width: 30px;
  margin-right: 5px;
  display: inline-block;
}
.Footer-container{
  width: 100%;
  background-color: white;
  margin: 0;
  padding-top: 100px;
  padding-bottom: 20px;
}

@media screen and (max-width:1260px)  {
  .images-hold1 {

    width: 100%;
    height: 300px;
    overflow: hidden;
    /* padding: 10px; */
    margin: 0 auto;
  }

  .images-hold3 {

    width: 100%;
    height: 350px;
    
  }

  .img1 {
    width: 100%;
    height: 300px;
    transition: 0.3s all ease-in-out;
  }

  .img2 {
    width: 100%;
    height: 300px;
    transition: 0.3s all ease-in-out;
  }

  .img3 {
    width: 100%;
    height: 350px;
    transition: 0.3s all ease-in-out;
    
  }
  
  .ImageCol {
    padding: 15px;
    display: block;
    justify-items: center;
  }
  .attached_headings {
    /* font-size: 1.5em; */
    width: 100%;
  }


}

/* Footer media query */

@media screen and (max-width:1080px) {
  .foot-img-view{
    width: 100%;
    padding: 20px;  
  }
  .Footer-content{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    padding: 2%;
    background-color:#042825;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: inline-block;
  }
  .Footer-container{
    padding-bottom: 0;
      }
      .foot-links-content{
        width: 100%;
        display: inline-flexbox;
        background-color:#042825;
      }
      .Email-in-join_us-btn{
        width: 70%;
        margin-left: 0;
        margin-right: 0;
        height: 80px;
        padding: 10px;
        display: flex;
        margin-top: 30px;
    transition: 0.3s all ease-in-out;

      }
      .links-container-footer{
        
    transition: 0.3s all ease-in-out;

      
      }
      .links-content{
    transition: 0.3s all ease-in-out;
       
    }
}

@media screen and (max-width:450px) {
  .Footer-container{
padding-bottom: 0;
  }
  .Footer-content{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    padding: 2%;
    background-color:#042825;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: inline-block;
  }
  .foot-img-view{
    width: 100%;
    padding: 20px;
   
    
  }
  .foot-links-content{
    width: 100%;
    display: inline-flexbox;
    background-color:#042825;
  }
  .Email-in-join_us-btn{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    height: 80px;
    padding: 10px;
    display: flex;
    margin-top: 30px;
  }
  .input-mail{
    width: 70%;
    height: 40px;
    font-size: 1.1em;
    border-radius: 30px  0 0 30px ;
    padding-left: 20px; 
    border: 3px solid #ffffff;
  }
  .joinus-foot-btn{
    width: 30%;
    height: 40px;
    
    font-size: 1.1em;
    border: 2px solid #ffffff;
    font-weight: bold;
    background-color: white;
     border-radius: 0 60px 60px 0 ;
     color: #073e3a;
  }
  .links-container-footer{
    
    display: flex;
    justify-content: space-between;
    color: white;
    text-align: start;
  
  }
  .links-content1{
      /* width: fit-content; */
      height: auto;
      
      font-size: 14px;
      background-color:#042825;
  }
  .links-content2{
    /* width: fit-content; */
    height: auto;
    text-align: center;
    font-size: 14px;
    background-color:#042825;
}
  .footer-web-logo{
    width: 180px;
    height: 80px;
  
    
  }
  .expert{
    font-size: 1.5em;
    color: gray;
    display: inline-flexbox;
    height: 80px;
    padding-top: 35px;
    font-weight: bold;
    margin-left: -15px;
  }
  .links-content h3{
    text-decoration: underline;
    padding: 5px;
    margin-bottom: 10px;
  }
  .links-content a{
    color: white;
    font-size: 1em;
    line-height: 2em;
    text-decoration: none;
  }
  .details-web{
    color: white;
    display: block;
  }
  .foot-logoC{
    display: flex;
  }
  #numStyle{
    font-size: 1.1em;
    line-height: 2em;
  }
  .IconsLink{
    width: 17px;
    margin-right: 1px;
  }
  .IconsLink1{
    width: 20px;
    margin-right: 0px;
  }
  

}
/* Plans page Css  start from here*/
.Plans-View{
  width: 100%;
  background-color: #042825;
  /* background-color: #fff; */
  height: 100%;
}
/* #92FFC0
#002661 */
.Plans-container{
  width:100%;
  display: flex;
  justify-content: space-evenly;
  /* background-color: #08AEEA;
  background-image: linear-gradient(0deg, #2AF598 0%,  #08AEEA 100%); */
  /* height: 800px; */
}
.plans-content-view{
  width: 300px;
  display: inline-block;
  height: fit-content;
  border-radius: 15px;
   /* background-color: #08AEEA; */
   background-color: white;
   /* box-shadow: 0 10px 32px 0 rgba(31, 38, 135, .37); */
 
   margin: 20px;
   text-align: center;
  /* background-image: linear-gradient(120deg,  #2AF598 0%,  #002661  100%); */

}
.Plans-View h1{
  color: #042825;
  text-align: center;
  font-weight: 700;
  font-size: 1.5em;
  margin:10px;
  
}

.plan-h2{
  color: white;
  display: inline-block;
  background-color: #01433c;
transition: .4s linear;
  padding: 10px;
/* margin-top: 20px; */
  font-size: 1.5em;
  border-radius: 0 0 10px 10px;
}


.plan-desgin{
  height: 98%;
  width: 98%;
  margin: 1%;
  border-radius: 15px;
  background-color: white;
padding: 0 10px 0px 10px;
  overflow: hidden;
  transition: 0.4s linear;
  border: 5px solid#01433c;
  
}

.li-list{
color:#042825;
font-size: 1.1em;
margin-top: 15px;
font-weight: bold;
text-align: start;
padding-left: 20px;


}


.btn-book{
  width:250px;
  height: 45px;
  padding: 5px;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  cursor: pointer;
 /* position: fixed; */
 /* top: 80%; */
  
  border: 2px solid  #01433c;
  font-weight: bold;
  margin-top: 70px;
  margin-bottom: 20px;
  background-color: #01433c;
  /* box-shadow: 0 20px 32px 0 rgba(31, 38, 135, .37); */
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.5);


}



@media screen and (max-width:1120px) {
  .Plans-container{
    width:100%;
    display: inline-block;
    justify-content: space-evenly;
    /* background-color: #08AEEA;
    background-image: linear-gradient(0deg, #2AF598 0%,  #08AEEA 100%); */
    height: 800px;
  }
}
.Auth-view{
  width: 100%;
  height: 100%;
  text-align: center;
  
  /* background-color: white; */
}

.Sign-in-up{
  width: 100%;
  padding: 25px;
  border: 2px solid white;
  border-radius: 20px;
}

.iconshield{
  background-color: #02a598;
  padding: 10px;
  width: 50px;
  height: 50px;
  color: white;
  margin-top: 20px;
  /* display: inline-block; */
margin-left: calc(50% - 30px);
  border-radius: 50%;
  
}
.numInput{
  color: white;
  font-size: 1.3em ;
  margin-top: 20px;
  display: block;
}
.Phone-in{
  margin-top: 30px;
  font-family: 700;
}
.input-fname{
    font-size: 1.1em;
    padding: 10px;
    border-radius: 5px;
    font-weight: 700;
}
.about-content{
  width: 100%;
  background-color: white;

}
.about-h1{
  margin-top: 60px;
  display: inline-block;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  color: #042825;
  font-size: 2.5em;
  font-weight: bold;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;    
}
.details-about{
color: #042825;
width: 70%;
margin-top: 50px;
margin-bottom: 80px;
margin-left: 15%;
font-weight: 500;
font-size: 1.5em;
animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;    

}
@keyframes fadeInAnimation {
  0%{

      transform: translateY(60px);
      opacity: 0;
  }
  100%{
      transform: translateY(0px);
      opacity: 1;
  }
}
@media screen and (max-width:450px) {
  .about-h1{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 10px;
  }
  .details-about{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .about-content{
height: 1000px;

  }
  table{
    width: fit-content;
    margin-left: 0;
    margin-right: 0;
    font-size: 16px;
  }
  .contact-links{
    flex-direction: column;
  }
  /* .icon-links-footer{
    width: 20px;
    height: 20px;
  } */

}
.about-footer{
  /* margin-top: 180px; */
  /* background-color: #042825; */
  /* height: 300px; */
  background-color: #042825;
}
.icon-links-about{
  width: 30px;
  height: 30px;
  margin: 20px;
}
.icon-links-footer{
  width: 20px;
  height: 20px;
  margin: 20px;
}
.footer-icons{
  display: flex;
  justify-content:center;

}
.contact-links{
  width: 100%;
  display: flex;

 color: rgb(16, 185, 127);
font-size: 1.1em;
font-weight: bold;  
}
.icon-links-about-c{
  width: 25px;
  height: 25px;
  margin: 20px;
  display: inline-block;
  
}
.a-about{
  display: inline-block;

  /* font-size: 1.2em; */
  
}