.NavBarContainer{
    width: 100%;
    height: 75px;
}
.Navlogo{
    width: 150px;
    height: 100%;
    justify-self: start;
    cursor: pointer;
}
.NavbarItems{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    font-size: 1.2rem;
    height: 80px;   
    background-color:#042825;
    position: fixed;
    z-index: 1;
}
.NavMenu{

    display: grid;
    grid-template-columns: repeat(5,auto);
    gap: 25px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}
.NavLinks{
    /* width: fit-content; */
    color: white;
    text-decoration: none;
    list-style: none;
    /* display: inline-block;  */
   position: relative;
  padding-bottom: 7px; 
}
 .NavLinks:after{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.NavLinks:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }  
.SignUpbtn{
    color: #042825;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    
    background-color: aliceblue;
    text-decoration: none;
    list-style: none;
}
.menuItems{
    display: none;
}
.fa-bars,.fa-times{
    color: #ffffff;
}
@media screen and (max-width:720px) {
    .NavbarItems{
        z-index: 99;
    }
    .NavMenu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color:white;
        /* background:rgba(255, 255, 255, .25); */
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, .37);
        backdrop-filter:blur(4px);
        border-radius: 13px;
        width: 100%;
        height: auto;
        position:absolute;
        top: 5px;
        left: -110%;
        align-items: stretch;
        padding: 80px 0 30px 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }
    .NavMenu.active{
        left: 0%;
        
    }
    .NavLinks{
        display: block;
        widows: 100%;
        padding: .2rem 0;
        font-size: 1.2rem;
        color: #042825;
        font-weight: 700;

    }
    .NavLinks:hover{
        /* background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(20px); */
        background-color: antiquewhite;
    }
    .menuItems{
        display: block;

    }
    .fa-times{
        color: #042825;
    }
    .SignUpbtn{
        font-size: 1.5rem;
        background-color: #042825;
        color: #ffffff;
        padding-top: 10px;
    padding-bottom: 10px;
    
  width: 80%;
  
  margin-left:10%;
    }
}
