.userListScroll {
    overflow-y: scroll;

}

.userListScroll::-webkit-scrollbar {
    display: none;
}

.loader div {
    animation-duration: 0.5s;
}

.loader div:first-child {
    animation-delay: 0.1s;
}

.loader div:nth-child(2) {
    animation-delay: 0.3s;
}

.loader div:nth-child(3) {
    animation-delay: 0.6s;
}
.loader div:nth-child(4) {
    animation-delay: 0.9s;
}

.loader div:nth-child(5) {
    animation-delay: 1.2s;
}
.loader div:nth-child(6) {
    animation-delay: 1.5s;
}