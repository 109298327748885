.plan-wrapper{
   position: fixed;
   top: 123px;
   left: 0;
   right: 0;
   bottom: 0;
   /* z-index: -1; */
   background-color: transparent;
    /* backdrop-filter: blur(5px); */

}
.plan-booked-view{
    position: fixed;
    width: 250px;
    max-width: 250px;
z-index: 1;
    top:50%;
    left: 50%;
    text-align: start;
    transform: translate(-50% ,-50%);
    padding: 10px 10px;
    background-color: white;
    border-radius: 10px;

}
.plan-booked-view h1{
    font-size: 1.2em;
    font-weight: bold;
    color:darkgreen;
    margin-bottom: 10px;
    /* margin: 5px; */
    border-radius: 5px;
    background-color: antiquewhite;
    padding: 10px;
}
.plan-booked-view h3{
    font-size: 1em;
    font-weight: 700;
    padding: 10px;
    
    
}

.btn-cancel{
    width: 30px;
    height: 30px;
    /* display: inline-block; */
    background-color:white;
    margin-left: calc(100% - 30px);
    margin-right: 0;
    margin-bottom: 5px;

}
.btn-cancel2{
    width: 100%;
    height: 30px;
    color: blue;
    /* display: inline-block; */
    background-color:antiquewhite;
    /* margin-left: calc(50% - 30px); */
    margin-right: 0;
    margin-bottom: 5px;
    font-weight: bold;

}

.mt-1{
    display: inline-block;
    
}
.fa-times{
    color:black;
}

.btn-cancel:hover{
    background-color:antiquewhite;
}